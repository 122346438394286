import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[visiblityObserver]'
})
export class VisiblityObserverDirective implements OnInit, OnDestroy {
  @Output() visibilityChange = new EventEmitter<boolean>();
  public observer: IntersectionObserver | null = null;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) =>
          this.visibilityChange.emit(entry.isIntersecting)
        );
      },
      { threshold: [0.1] }
    );

    this.observer.observe(this.element.nativeElement);
  }

  ngOnDestroy() {
    this.observer?.disconnect();
  }
}
