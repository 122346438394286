import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from "../../../environments/environment";
import { LoaderService } from "./loader.service";

Sentry.init({
  dsn: "https://8377043970684e86b698c21289440ca6@o1107930.ingest.sentry.io/6473980",
  environment: environment.name,
  enabled: environment.ENABLE_SENTRY,
  tracePropagationTargets: [environment.BASE_URL],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.breadcrumbsIntegration({
      console: false,
    }),
    Sentry.dedupeIntegration()
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    console.log("sentry beforesend ===> ", hint.originalException);
    return event;
  },
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loader: LoaderService) {}

  handleError(error): void {
    if (environment.name != 'production') {
      console.log(error);
    }
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      this.loader.hide();
      Sentry.captureException(
        error?.originalError || error?.error || error?.message || error,
      );
    }
  }
}
