import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WarningService {
  showWarning = new Subject<{ id: string; status: boolean }>();
  show(id: string) {
    this.showWarning.next({ id: id, status: true });
  }
  hide() {
    this.showWarning.next({ id: "", status: false });
  }
}
