export enum ToasterActionTypes {
  SET_SUCCESS_MESSAGE = "[TOASTER] Set Success Message",
  SET_ERROR_MESSAGE = "[TOASTER] Set Error Message",
  SHOW_TOASTER = "[TOASTER] Show",
}

//Set
export class SetToasterSuccessMsg {
  static readonly type = ToasterActionTypes.SET_SUCCESS_MESSAGE;
  constructor(
    public toastMessage: string,
    public time?: number,
  ) {}
}

export class SetToasterErrorMsg {
  static readonly type = ToasterActionTypes.SET_ERROR_MESSAGE;
  constructor(
    public toastMessage?: string,
    public time?: number,
  ) {}
}

//Show
export class ShowToasterMsg {
  static readonly type = ToasterActionTypes.SHOW_TOASTER;
  constructor(public isToasterEnable: boolean) {}
}
