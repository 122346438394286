import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { DataService } from "../../../shared/shared-module/services/data.service";
import { HttpRequests } from "../../../shared/shared-module/services/http-requests.service";
import { Constants } from "../../../shared/shared-module/utility/constants";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  constructor(
    public dataService: DataService,
    public httpRequest: HttpRequests,
  ) {}

  public getMyTasks(page = 1) {
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.myTask +
      "?page=" +
      page;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs("application/json"))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }
  public updateTask(todoObject, id) {
    console.log(todoObject.status);
    const data = new FormData();
    data.append("todo[todo_type]", todoObject.todo_type);
    data.append("todo[description]", todoObject.description);
    data.append("todo[status]", todoObject.status);
    if (todoObject.builder_card_phase_id) {
      data.append(
        "todo[builder_card_phase_id]",
        todoObject.builder_card_phase_id,
      );
    }
    if (todoObject.due_date) {
      data.append("todo[due_date]", todoObject.due_date);
    }
    if (todoObject.assignee_id) {
      data.append("todo[assignee_id]", todoObject.assignee_id);
    }
    if (todoObject.assignee_type) {
      data.append("todo[assignee_type]", todoObject.assignee_type);
    }
    if (todoObject.fileToBeUpload && todoObject.remove_attachment) {
      data.append(
        "todo[attachments_attributes][0][id]",
        todoObject.attachments[0].id,
      );
      data.append(
        "todo[attachments_attributes][0][file]",
        todoObject.fileToBeUpload,
      );
    } else if (todoObject.fileToBeUpload) {
      data.append(
        "todo[attachments_attributes][0][file]",
        todoObject.fileToBeUpload,
      );
    } else if (todoObject.remove_attachment && todoObject.attachments[0].id) {
      data.append(
        "todo[attachments_attributes][0][id]",
        todoObject.attachments[0].id,
      );
      data.append("todo[attachments_attributes][0][_destroy]", "true");
    }
    const API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.myTask +
      "/" +
      id;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }
}
