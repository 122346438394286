import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NotificationCenterService } from './services/notification-center.service';
import { Subject, distinctUntilChanged, map, throttleTime } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { NotificationCenterRequestActionType } from './enum/request-action';
import { NotificationData } from './interfaces/notification-center';
import moment from 'moment';

@Component({
  selector: 'notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
  @ViewChild('notificationWrapper') notificationWrapper!: ElementRef;
  isActiveUnread = false;
  notifications$: Observable<Array<{ key: string; value: NotificationData[] }>>;
  unreadNotifications$: Observable<
    Array<{ key: string; value: NotificationData[] }>
  >;
  unreadCount$: Observable<number>;
  unseenCount$: Observable<number>;
  perPage = 20;
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;
  @Input() page = 1;
  @Input() isLoading = false;
  @Output() closeNotiCenter = new EventEmitter<void>();
  @Output() performNotificationSocketAction = new EventEmitter<{
    type: NotificationCenterRequestActionType;
    data?: any;
  }>();

  constructor(private notificationCenterService: NotificationCenterService) {
    this.notifications$ = this.notificationCenterService.getAllNotifications();
    this.unreadNotifications$ =
      this.notificationCenterService.getUnreadNotifications();
    this.unreadCount$ =
      this.notificationCenterService.getUnreadNotificationsCount();
    this.unseenCount$ =
      this.notificationCenterService.getUnseenNotificationsCount();
  }

  ngOnInit() {
    /// Fetch All notifications
    this.notificationCenterService.setIsLoading(true);
    this.notificationCenterService.setNextPage(1);
    this.performNotificationSocketAction.emit({
      type: NotificationCenterRequestActionType.Get_All_Notifications,
      data: {
        page: 1,
        perPage: this.perPage
      }
    });
  }

  closeSidebar() {
    this.closeNotiCenter.emit();
  }

  onChangeUnreadAllTab(isUnreadTab: boolean) {
    this.isActiveUnread = isUnreadTab;
    this.notificationCenterService.setIsLoading(true);
    this.notificationCenterService.setNextPage(1);
    this.performNotificationSocketAction.emit({
      type: this.isActiveUnread
        ? NotificationCenterRequestActionType.Get_Unread_Notifications
        : NotificationCenterRequestActionType.Get_All_Notifications,
      data: {
        page: 1,
        perPage: this.perPage
      }
    });
  }

  onScroll() {
    if (this.page && !this.isLoading) {
      this.notificationCenterService.setIsLoading(true);
      this.performNotificationSocketAction.emit({
        type: this.isActiveUnread
          ? NotificationCenterRequestActionType.Get_Unread_Notifications
          : NotificationCenterRequestActionType.Get_All_Notifications,
        data: {
          page: this.page,
          perPage: this.perPage
        }
      });
    }
  }

  markAsRead(ids: number[]) {
    this.performNotificationSocketAction.emit({
      type: NotificationCenterRequestActionType.Mark_As_Read,
      data: {
        notificationIds: ids
      }
    });
  }

  markAsSeen(ids: number[]) {
    this.performNotificationSocketAction.emit({
      type: NotificationCenterRequestActionType.Mark_As_Seen,
      data: {
        notificationIds: ids
      }
    });
    this.notificationCenterService.removeUnseenNotificationIds(ids);
  }

  markAllAsRead() {
    this.performNotificationSocketAction.emit({
      type: NotificationCenterRequestActionType.Mark_All_As_Read
    });
  }

  openNotification(url: string, id: number, isRead: boolean) {
    if (!isRead && !this.checkIsReadByID(id)) {
      this.markAsRead([id]);
      const storedArray = localStorage.getItem('clickedIds');
      let array: any[] = storedArray ? JSON.parse(storedArray) : [];
      array.push(id);
      if (array.length > 50) {
        array = array.slice(-50);
      }
      localStorage.setItem('clickedIds', JSON.stringify(array));
    }
    window.open(window.location.origin + '/#/' + url, '_blank');
  }

  get notificationByTab$() {
    return this.isActiveUnread
      ? this.unreadNotifications$
      : this.notifications$;
  }

  isElementInViewport(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  getTimezone() {
    const momentTz = moment.tz(moment.tz.guess());
    const activeZone1 = momentTz.format('Z');
    const activeZone2 = momentTz.format('z');
    const activeTzAbbr =
      activeZone1.substring(0, 3) == activeZone2.substring(0, 3)
        ? ' (' + activeZone1 + ' GMT)'
        : ' ' + activeZone2;
    return activeTzAbbr;
  }

  checkToday(time) {
    const givenDate = moment.utc(time * 1000).tz(moment.tz.guess());
    const today = moment.tz();
    if (givenDate.isSame(today, 'day')) {
      return true;
    } else {
      return false;
    }
  }

  giveDuration(utcTimestamp) {
    const utcTime = moment.utc(utcTimestamp);
    const localTime = utcTime.local();
    const currentTime = moment.tz(moment.tz.guess());
    const durationMinutes = currentTime.diff(localTime, 'minutes');

    if (durationMinutes > 60) {
      const localTimezone = moment.tz.guess();
      return `${moment.utc(utcTime).tz(localTimezone).format('h:mm a')} ${this.getTimezone()}`;
    } else {
      return `${durationMinutes ? `${durationMinutes} min` : 'Just now'}`;
    }
  }

  onVisibilityChange(isVisible: boolean, item: any) {
    if (isVisible && !item.is_seen) {
      this.markAsSeen([item.id]);
    }
  }

  checkIsReadByID(id) {
    const savedClickedIds = localStorage.getItem('clickedIds');
    const clickedIds = savedClickedIds ? JSON.parse(savedClickedIds) : [];
    if (clickedIds.includes(id)) {
      return true;
    } else {
      return false;
    }
  }
}
