import { Injectable } from "@angular/core";
import { throwError, BehaviorSubject } from "rxjs";
import { DataService } from "./data.service";
import { HttpRequests } from "./http-requests.service";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CallScoreService {
  public showCallScorePopup = false;
  public callScoreData = [];
  public notifyCallScoreData = new BehaviorSubject(null);
  constructor(
    private httpRequest: HttpRequests,
    public dataService: DataService,
  ) {}
  public notifyCallScore(data) {
    if (data) {
      this.notifyCallScoreData.next(data);
    }
  }
  public updateSpecCall(specId, data) {
    const API = this.dataService.getServerUrl() + "spec_calls/" + specId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(""))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public closeInstantNotificationPopup(id) {
    const API = `${this.dataService.getServerUrl()}meetings/${id}/close_instant_meeting_popup`;
    return this.httpRequest
      .post(
        API,
        { email: this.dataService.getUserData().email },
        this.dataService.getRequestOptionArgs('')
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }
}
