import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataService } from "../../../shared/shared-module/services/data.service";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { Constants } from "../shared/constants";

@Injectable({
  providedIn: 'root'
})
export class ManagerService {
  API_BASE_URL = environment.API_BASE_URL;
  managerTabStatus;
  managerID: number;
  defaultManagerGroup;

  constructor(public http: HttpClient, private dataService: DataService) {}

  getAllManager(
    page?,
    perPage?,
    query?,
    value?,
    filterStr?,
    min?,
    partnerId?,
    search_email?
  ) {
    let API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}?page=${page}&per_page=${perPage}`;

    if (query) {
      API = `${API}&search=${query}`;
    }
    if (value) {
      API = `${API}&filter=status&value=${value}`;
    }

    if (filterStr) {
      API = `${API}&${filterStr}`;
    }

    if (min) {
      API = `${API}&min=${min}`;
    }

    if (partnerId) {
      API = `${API}&status[]=${partnerId}_false`;
    }

    if (search_email) {
      API = `${API}&internal_user=${search_email}`;
    }
    return this.http
      .get(API, this.dataService.getRequestOptionArgs("application/json"))
      .pipe(
        map((res) => {
          if (!query && !filterStr && !partnerId && !min && !value) {
            this.defaultManagerGroup = res['data'];
          }
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        }),
      );
  }

  addManagers(manager) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.inviteManager}`;

    return this.http
      .post(
        API,
        JSON.stringify(manager),
        this.dataService.getRequestOptionArgs("application/json"),
      )
      .pipe(
        map((res) => res),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public updateManager(manager, managerId) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}/${managerId}`;
    return this.http
      .patch(
        API,
        JSON.stringify(manager),
        this.dataService.getRequestOptionArgs("application/json"),
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public getManagerById(id) {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getAllManager}/${id}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs("application/json"))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public sendReInvite(managerID) {
    const invite = {
      invite_user_id: managerID
    };
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.reInviteUser}`;
    return this.http
      .post(
        API,
        JSON.stringify(invite),
        this.dataService.getRequestOptionArgs("application/json"),
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  public updateManagerStatus(id, value) {
    const API = `${
      this.API_BASE_URL
    }${Constants.apiEndPoints.archiveManager.replace(
      "[manager_id]",
      id,
    )}${value}`;
    return this.http
      .post(
        API,
        JSON.stringify(""),
        this.dataService.getRequestOptionArgs("application/json"),
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  getManagerSquad() {
    const API = `${this.API_BASE_URL}${Constants.apiEndPoints.getPods}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs("application/json"))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        }),
      );
  }

  getMemberTimeline(memberId) {
    const API = `${
      this.API_BASE_URL
    }${Constants.apiEndPoints.memberTimeline.replace('[admins_id]', memberId)}`;
    return this.http
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error.error);
        })
      );
  }
}
