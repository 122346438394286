import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('email') public email;
  public showSucess = false;
  public data = { email: "" };
  public ErrorMsg = "";

  constructor(
    public loaderService: LoaderService,
    public commonService: CommonService,
    public dataService: DataService,
    public router: Router,
    public apiRequest: ApiRequests,
  ) {}

  public ngOnInit() {
    this.commonService.newHeadertrue = true;
    if (this.dataService.getUserData()) {
      this.router.navigate(["/projects"]);
      this.commonService.newHeadertrue = false;
      return;
    }
  }

  public checkIfEmailValid(): boolean {
    return (
      this.email &&
      this.email.errors &&
      (this.email.dirty || this.email.touched)
    );
  }

  public forgotClick() {
    this.loaderService.show();
    this.apiRequest.login_signup(this.data, 'forgot').subscribe(
      () => {
        this.showSucess = true;
        this.commonService.newHeadertrue = true;
        this.loaderService.hide();
      },
      (error) => {
        this.loaderService.hide();
        this.ErrorMsg = error.error;
        if (this.ErrorMsg === 'User not found') {
          this.router.navigate(['/forgot-password']);
          this.ErrorMsg = 'Please Enter Registered Email Id';
        }
        setTimeout(() => {
          this.ErrorMsg = '';
          this.data.email = '';
        }, 5000);
      },
    );
  }
}
