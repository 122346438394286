import { Injectable } from '@angular/core';

import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { HttpRequests } from '../../../shared/shared-module/services/http-requests.service';
import { SharedService } from '../../../shared/shared-module/services/shared.service';
import { Constants } from '../../../shared/shared-module/utility/constants';

@Injectable({
  providedIn: 'root'
})
export class SpecCallService {
  constructor(
    private httpRequest: HttpRequests,
    public dataService: DataService,
    public commonService: CommonService,
    public sharedService: SharedService
  ) {}
  isSuccessTransferred = false;
  selectedProjectName = '';
  buildCardFeatures = [];
  buildCardPhases = [];
  buildCardSpeed;
  buildCardTemplates = [];
  isTemplateChange = true;
  isFeatureChange = true;
  pooling = false;
  isSourceCockpit = true;
  isFetchBuildCardInProgress = false;
  updateCard = false;
  buildCardPlatforms = [];
  buildCardPlatformsAttributes = [];
  buildCardFeaturesAttributes = [];
  buildCardTemplatesAttributes = [];
  upperCustomerExpectation;
  upperCustomerTimeline;
  configurationData;
  isBuildCardUpdated = false;
  searchRetained = {
    spec: {
      instant: '',
      scheduled: ''
    },
    demo: {
      instant: '',
      scheduled: ''
    }
  };
  specTabActive = '';

  public notifyCurrentOpenedTemplateModalTypeSubject: BehaviorSubject<string> =
    new BehaviorSubject(null);

  public notifyUpdateFeaturesSubject: BehaviorSubject<any> =
    new BehaviorSubject(null);

  public currentOpenedTemplateModalType(
    val: 'customtemplate' | 'template' | null
  ) {
    this.notifyCurrentOpenedTemplateModalTypeSubject.next(val);
  }

  public notifyUpdateFeatures(val) {
    this.notifyCurrentOpenedTemplateModalTypeSubject.next(val);
  }

  public getSpecCall(
    call_type?,
    upcoming_page?,
    upcoming_per_page?,
    past_page?,
    past_per_page?,
    type?,
    query?
  ) {
    let API =
      this.dataService.getServerUrl() +
      'spec_calls?call_type=' +
      call_type +
      '&upcoming_page=' +
      upcoming_page +
      '&upcoming_per_page=' +
      upcoming_per_page +
      '&past_page=' +
      past_page +
      '&past_per_page=' +
      past_per_page +
      '&type=' +
      type;
    if (query) {
      API = API + '&search=' + query;
    }
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getSpecCallById(spec_id) {
    const API = this.dataService.getServerUrl() + 'spec_calls/' + spec_id;
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public createBuildCard(specCallId, email, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'pm_dashboard/build_cards';
    const data = {
      spec_call_id: specCallId,
      email: email
    };
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );

    return this.httpRequest.post(API, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public updateSpecCall(specId, data) {
    const API = this.dataService.getServerUrl() + 'spec_calls/' + specId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(''))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public addProjectClient(selectedProjecId, client, Isfrom360Invite, callData) {
    let API;
    if (Isfrom360Invite === true) {
      API =
        this.dataService.getServerUrl() +
        Constants.apiEndPoints.get_post +
        '/' +
        selectedProjecId +
        '/' +
        Constants.apiEndPoints.client;

      if (!API.includes('forceid=')) {
        API = API + '?forceid=' + selectedProjecId;
      }
      return this.httpRequest
        .post(
          API,
          JSON.stringify(client),
          this.dataService.getRequestOptionArgs('application/json')
        )
        .pipe(
          map((res) => {
            return res.data;
          }),
          catchError((error) => {
            return throwError(() => error);
          })
        );
    } else if (Isfrom360Invite === false) {
      API =
        this.dataService.getServerUrlForAccounts(callData) +
        'pm_dashboard/build_card/pm_dashboard_invite';
      const httpOptions = { headers: new HttpHeaders() };
      httpOptions.headers = httpOptions.headers.set(
        'apptoken',
        this.dataService.getAppTokenForAccounts()
      );
      return this.httpRequest.post(API, client, httpOptions).pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
    }
  }

  public checkIsUserInvited(buildCardId, email: string, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'pm_dashboard/build_card/build_card_invite_check?build_card_id=' +
      buildCardId +
      '&email=' +
      email;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getStudioConfiguration(callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) + 'configurations';
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getStudioBuildCard(uniqCode, email, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'build_cards/' +
      uniqCode +
      '?email=' +
      email;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getAllTemplatesStudio(
    callData,
    page,
    currency_id,
    multiplierData = null
  ) {
    let API =
      this.dataService.getServerUrlForAccounts(callData) +
      'api/v1/templates?page=' +
      page +
      '&currency_id=' +
      currency_id;

    if (multiplierData) {
      API +=
        '&price_multiplier=' +
        multiplierData.price_multiplier +
        '&duration_multiplier=' +
        multiplierData.duration_multiplier +
        '&custom_price_multiplier=' +
        multiplierData.custom_price_multiplier +
        '&user_price_multiplier=' +
        multiplierData.user_price_multiplier;
    }

    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getAllFeaturesStudio(
    page,
    currency_id,
    callData,
    multiplierData = null
  ) {
    let API =
      this.dataService.getServerUrlForAccounts(callData) +
      'features?currency_id=' +
      currency_id +
      '&page=' +
      page;

    if (multiplierData) {
      API +=
        '&price_multiplier=' +
        multiplierData.price_multiplier +
        '&duration_multiplier=' +
        multiplierData.duration_multiplier +
        '&custom_price_multiplier=' +
        multiplierData.custom_price_multiplier +
        '&user_price_multiplier=' +
        multiplierData.user_price_multiplier;
    }

    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getAllTeamsStudio(callData) {
    const API = this.dataService.getServerUrlForAccounts(callData) + 'teams';
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public updateBuildCard(build_card_id, data, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'pm_dashboard/build_cards/' +
      build_card_id;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.put(API, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public updateBuildCardToGetPricingOnly(build_card_id, data, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'pm_dashboard/build_cards/' +
      build_card_id;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest
      .put(API, { ...data, pricing_required: true }, httpOptions)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public searchFeature(data, page, callData, multiplierData = null) {
    let API =
      this.dataService.getServerUrlForAccounts(callData) +
      'features/new_feature_bundle_search?page=' +
      page;
    if (multiplierData) {
      API +=
        '&price_multiplier=' +
        multiplierData.price_multiplier +
        '&duration_multiplier=' +
        multiplierData.duration_multiplier +
        '&custom_price_multiplier=' +
        multiplierData.custom_price_multiplier +
        '&user_price_multiplier=' +
        multiplierData.user_price_multiplier;
    }
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.post(API, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public searchTemplates(
    query,
    currency_id,
    page,
    callData,
    multiplierData = null
  ) {
    let API =
      this.dataService.getServerUrlForAccounts(callData) +
      'api/v1/templates/search?search[query]=' +
      query +
      '&currency_id=' +
      currency_id +
      '&page=' +
      page;

    if (multiplierData) {
      API +=
        '&price_multiplier=' +
        multiplierData.price_multiplier +
        '&duration_multiplier=' +
        multiplierData.duration_multiplier +
        '&custom_price_multiplier=' +
        multiplierData.custom_price_multiplier +
        '&user_price_multiplier=' +
        multiplierData.user_price_multiplier;
    }
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  prepareCommonUpdateData(buildCardData) {
    const platforms = [];
    if (buildCardData.progress.data.attributes.platforms.data) {
      buildCardData.progress.data.attributes.platforms.data.forEach((d) => {
        platforms.push(d.attributes.id);
      });
    }
    const data = {
      build_card: {
        project_name: buildCardData.project_name,
        customized_template_name: buildCardData.customized_template_name,
        product_id:
          buildCardData.progress.data &&
          buildCardData.progress.data.attributes.product &&
          buildCardData.progress.data.attributes.product.data
            ? buildCardData.progress.data.attributes.product.data.attributes.id
            : null,
        platform_ids: platforms,
        team_id: buildCardData.progress.data.attributes.team.data
          ? buildCardData.progress.data.attributes.team.data.attributes.id
          : null,
        speed_id: buildCardData.progress.data.attributes.speed.data
          ? buildCardData.progress.data.attributes.speed.data.attributes.id
          : null,
        promotion_id: buildCardData.promotion
          ? buildCardData.promotion.data.attributes.id
          : null,
        competitors: buildCardData.competitors
          ? buildCardData.competitors
          : null,
        status: buildCardData.status,
        is_under_support: this.getIsUnderSupport(buildCardData),
        is_advance: buildCardData.is_advance,
        multiplier_experiment_id: buildCardData.multiplier_experiment
          ? buildCardData.multiplier_experiment.data.id
          : null,
        currency_id: buildCardData.currency
          ? buildCardData.currency.data.attributes.id
          : null,
        source: 'cockpit',
        upfront: buildCardData.upfront,
        cloud_opted:
          buildCardData.cloud_opted != null ? buildCardData.cloud_opted : true,
        cloud_price: buildCardData.cloud_price,
        cloud_user_count: buildCardData.cloud_user_count
      },
      email: this.dataService.user.email.trim()
    };

    return data;
  }

  getIsUnderSupport(buildCardData) {
    if (buildCardData.status == 'card_completed') {
      if (buildCardData.subscription_flow) {
        if (
          buildCardData.is_under_support == null ||
          buildCardData.is_under_support == undefined
        ) {
          return false;
        } else {
          return buildCardData.is_under_support;
        }
      } else {
        if (
          buildCardData.is_under_support == null ||
          buildCardData.is_under_support == undefined
        ) {
          return true;
        } else {
          return buildCardData.is_under_support;
        }
      }
    } else {
      return false;
    }
  }

  createPhaseObjForUpdate(studioPhases, phaseId?) {
    const phaseObj = [];
    studioPhases.forEach((phase) => {
      if (
        phase.attributes.active ||
        (phaseId && phaseId == phase.attributes.id)
      ) {
        const data = {
          is_parallel_development: phase.attributes.is_parallel_development,
          index: phase.attributes.index,
          build_phase_id:
            phase.attributes.phase_type == 'system'
              ? phase.attributes.id
              : null,
          phase_type: phase.attributes.phase_type,
          speed_id: phase.attributes.speed_id,
          delivery_time: phase.attributes.delivery_time,
          id: phase.attributes.mapping_id,
          title: phase.attributes.title
        };
        if (phaseId && phaseId == phase.attributes.id) {
          data['_destroy'] = true;
        }
        if (
          (phase.attributes.phase_type == 'system' &&
            phase.attributes.title == 'MVP') ||
          phase.attributes.phase_type == 'custom'
        ) {
          data['build_card_phase_features_attributes'] = [];
          const features = [];
          if (phase.attributes.selectedFeatures) {
            phase.attributes.selectedFeatures
              .filter((x) => {
                return !!this.buildCardFeatures.find(
                  (y) => x.feature_id == y.attributes?.feature_id
                );
              })
              .forEach((f) => {
                const feature = {
                  feature_id: f.feature_id,
                  platform_ids: f.platform_ids,
                  id: f.id
                };
                if (f._destroy) {
                  feature['_destroy'] = f._destroy;
                }
                features.push(feature);
              });
          }
          data['build_card_phase_features_attributes'] = features;
          if (
            phase.attributes.phase_type == 'system' &&
            phase.attributes.title == 'MVP'
          ) {
            data['type'] = 'BuildCardMvpPhase';
            data['title'] = 'MVP';
          } else if (phase.attributes.phase_type == 'custom') {
            data['type'] = 'BuildCardMvpPhase';
            data['title'] = phase.attributes.title;
          }
        } else {
          data['platform_ids'] = phase.attributes.platform_ids;
        }
        phaseObj.push(data);
      }
    });
    return phaseObj;
  }

  createFeatureObjForUpdate() {
    const updatedFeatureObj = [];
    this.buildCardFeatures.forEach((f) => {
      const obj = {
        // id: f.id,
        feature_id: f.attributes ? f.attributes.feature_id : f.id,
        _destroy: false
      };
      updatedFeatureObj.push(obj);
    });
    return updatedFeatureObj;
  }

  createPhaseIdObj(studioPhases) {
    const phaseObj = [];
    studioPhases.forEach((p) => {
      if (p.attributes.active && p.attributes.phase_type == 'system') {
        phaseObj.push(p.attributes.id);
      }
    });
    return phaseObj;
  }

  corePhases = ['1', '2', '3', '4', '5'];
  speedArr = {
    3: { percent: '20', type: 'Slow' },
    4: { percent: '40', type: 'Relaxed' },
    1: { percent: '60', type: 'Standard' },
    5: { percent: '80', type: 'Fast' },
    2: { percent: '100', type: 'Speedy' }
  };

  removeCustomPhases(studioPhases) {
    return studioPhases.filter((el) => this.corePhases.includes(el.id));
  }

  getSelectedPhaseById(id) {
    return this.buildCardPhases.filter((p) => p.attributes.id == id)[0];
  }

  getSelectedPhaseByTitle(title) {
    return this.buildCardPhases.filter((p) => p.attributes.title == title)[0];
  }

  preparePhases(studioPhases) {
    const selectedPhases = [...this.buildCardPhases];
    const selectedPhaseIds = [];
    const selectedPhaseTitles = [];
    studioPhases = this.removeCustomPhases(studioPhases);
    selectedPhases.forEach((el) => {
      selectedPhaseIds.push(
        el.attributes.build_phase_id
          ? el.attributes.build_phase_id
          : el.attributes.id
      );
      selectedPhaseTitles.push(el.attributes.title);
    });
    selectedPhases.forEach((sPhase) => {
      if (sPhase.attributes.phase_type == 'custom') {
        studioPhases.push(sPhase);
      }
    });
    if (selectedPhases.length) {
      studioPhases.forEach((element) => {
        element.attributes.active = false;
        const selectPhase = this.getSelectedPhaseByTitle(
          element.attributes.title
        );
        if (selectPhase) {
          element.attributes.active = true;
        }
        if (this.corePhases.includes(element.id)) {
          element.attributes.phase_type = 'system';
        } else {
          element.attributes.phase_type = 'custom';
        }
        selectedPhases.forEach((phase) => {
          if (
            phase.attributes.phase_type == 'custom' &&
            element.attributes.phase_type == 'custom' &&
            phase.attributes.title == element.attributes.title
          ) {
            element.attributes.active = true;
            element.attributes.selectedFeatures = phase.attributes.features;
            element.attributes.mapping_id = phase.id;
            element.attributes.platform_ids = phase.attributes.platform_ids
              ? phase.attributes.platform_ids
              : phase.attributes.features
              ? [
                  ...new Set(
                    // eslint-disable-next-line
                    [].concat.apply(
                      [],
                      phase.attributes.features.map((x) => x.platform_ids)
                    )
                  )
                ]
              : [];
            element.attributes.speed_id = phase.attributes.speed_id;
            element.attributes.speed = this.speedArr[phase.attributes.speed_id];
          } else if (phase.attributes.phase_type == 'system') {
            if (phase.attributes.title == element.attributes.title) {
              element.attributes.phase_type = 'system';
              element.attributes.speed_id = phase.attributes.speed_id;
              element.attributes.is_parallel_development =
                phase.attributes.is_parallel_development;
              element.attributes.platform_ids = phase.attributes.platform_ids
                ? phase.attributes.platform_ids
                : phase.attributes.features
                ? [
                    ...new Set(
                      // eslint-disable-next-line
                      [].concat.apply(
                        [],
                        phase.attributes.features.map((x) => x.platform_ids)
                      )
                    )
                  ]
                : [];
              element.attributes.mapping_id = phase.id;
              element.attributes.active = true;
              if (phase.attributes.title == 'MVP') {
                element.attributes.phase_type = 'system';
                element.attributes.selectedFeatures = phase.attributes.features;
              } else {
                element.attributes.selectedFeatures = this.buildCardFeatures;
              }
              element.attributes.speed =
                this.speedArr[phase.attributes.speed_id];
              element.attributes.delivery_time = phase.attributes.delivery_time;
            } else if (!element.attributes.active) {
              element.attributes.active = false;
              element.attributes.selectedFeatures = [];
              element.attributes.speed = null;
              delete element.attributes['speed_id'];
              delete element.attributes['mapping_id'];
              delete element.attributes['platform_ids'];
              delete element.attributes['total_delivery_time'];
              delete element.attributes['delivery_time'];
              delete element.attributes['selectedFeatures'];
            }
          }
        });
      });
    }
    studioPhases.sort(function (a, b) {
      return b.attributes.active - a.attributes.active;
    });
    return {
      studioPhases: studioPhases,
      selectedPhaseIds: selectedPhaseIds,
      selectedPhaseTitles: selectedPhaseTitles
    };
  }

  fetchCategories(currency_id, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'categories?currency_id=' +
      currency_id;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public fetchProductsList(keyword) {
    const API = Constants.apiEndPoints.productListUrl.replace(
      '[query]',
      keyword
    );
    const httpOptions = { headers: new HttpHeaders() };
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public addNewTemplate(template, platforms, callData) {
    let API = this.dataService.getServerUrlForAccounts(callData);
    API = API + Constants.apiEndPoints.add_new_templates;
    const featureData = new FormData();
    for (const key in template) {
      if (template.hasOwnProperty(key)) {
        const value = template[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          if (key === 'reference_urls') {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                featureData.append(
                  underScoredKey + Constants.apiEndPoints.BRACKETS + k,
                  v
                );
              }
            }
          } else {
            for (const item of value) {
              featureData.append(
                underScoredKey + Constants.apiEndPoints.BRACKETS,
                item
              );
            }
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }

    if (platforms) {
      platforms.forEach((platform) => {
        featureData.append('[][platform_ids][]', platform.id);
      });
    }
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.post(API, featureData, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addNewFeature(feature, currency_id, multiplier_experiment, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      Constants.apiEndPoints.add_new_feature;
    const featureData = new FormData();
    for (const key in feature) {
      if (feature.hasOwnProperty(key)) {
        const value = feature[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          if (key === 'reference_urls') {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                featureData.append(
                  underScoredKey + Constants.apiEndPoints.BRACKETS + k,
                  v
                );
              }
            }
          } else {
            for (const item of value) {
              featureData.append(
                underScoredKey + Constants.apiEndPoints.BRACKETS,
                item
              );
            }
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }
    this.setCurrencyId(featureData, currency_id);
    if (multiplier_experiment) {
      multiplier_experiment = multiplier_experiment.attributes;
      this.setMultiplierExperimentParams(featureData, multiplier_experiment);
    }
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.post(API, featureData, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public updateFeature(feature, currency_id, multiplier_experiment, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      Constants.apiEndPoints.add_new_feature +
      '/' +
      feature.id;
    const featureData = new FormData();
    for (const key in feature) {
      if (feature.hasOwnProperty(key)) {
        const value = feature[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          if (key === 'reference_urls') {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                featureData.append(
                  underScoredKey + Constants.apiEndPoints.BRACKETS + k,
                  v
                );
              }
            }
          } else {
            for (const item of value) {
              featureData.append(
                underScoredKey + Constants.apiEndPoints.BRACKETS,
                item
              );
            }
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }
    this.setCurrencyId(featureData, currency_id);
    if (multiplier_experiment) {
      multiplier_experiment = multiplier_experiment.attributes;
      this.setMultiplierExperimentParams(featureData, multiplier_experiment);
    }
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.put(API, featureData, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  setMultiplierExperimentParams(params, multiplier_experiment) {
    params.append(
      'duration_multiplier',
      multiplier_experiment.duration_multiplier
        ? multiplier_experiment.duration_multiplier
        : ''
    );
    params.append(
      'price_multiplier',
      multiplier_experiment.price_multiplier
        ? multiplier_experiment.price_multiplier
        : ''
    );
    return params;
  }

  setCurrencyId(params, currency_id) {
    params.append('currency_id', String(currency_id));
    return params;
  }

  getPromotions(callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'promotion/percentage_promotions';
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  fetchPromotionData(code, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      Constants.apiEndPoints.get_promotion +
      '?promo_code=' +
      encodeURI(code.toLowerCase());
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  featureBundles(
    callData,
    templates,
    selectedFeatures,
    currencyId,
    multiplier_experiment,
    id?
  ) {
    const params = new FormData();
    if (id) {
      params.append('bundle_id', String(id));
    }
    if (
      templates != null &&
      templates.constructor === Array &&
      templates.length > 0
    ) {
      const underScoredKey = 'template_ids';
      for (const item of templates) {
        params.append(underScoredKey + Constants.apiEndPoints.BRACKETS, item);
      }
    }
    if (selectedFeatures && selectedFeatures.constructor === Array) {
      const underScoredKey = 'selected_feature_ids';
      if (selectedFeatures.length == 0) {
        params.append(underScoredKey + Constants.apiEndPoints.BRACKETS, '[]');
      } else {
        for (const item of selectedFeatures) {
          params.append(underScoredKey + Constants.apiEndPoints.BRACKETS, item);
        }
      }
    }
    this.setCurrencyId(params, currencyId);
    if (multiplier_experiment) {
      multiplier_experiment = multiplier_experiment.attributes;
      this.setMultiplierExperimentParams(params, multiplier_experiment);
    }
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      Constants.apiEndPoints.features;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.post(API, params, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public getManagers(page?, perPage?, query?, value?, min?) {
    let API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.manager +
      '?page=' +
      page +
      '&per_page=' +
      perPage;

    if (query) {
      API = API + '&search=' + query;
    }
    if (value) {
      API = API + '&filter=status' + '&value=' + value;
    }

    if (min) {
      API = API + '&min=' + min;
    }

    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data;
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public updateParticipant(specId, data) {
    const API = this.dataService.getServerUrl() + 'spec_calls/' + specId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(''))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public updateSpecCallDescription(specId, data) {
    const API = this.dataService.getServerUrl() + 'spec_calls/' + specId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(''))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public cancelCall(specId, data) {
    const API = this.dataService.getServerUrl() + 'spec_calls/' + specId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(''))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public updateFeatureTag(specId, featureTagId, data) {
    const API =
      this.dataService.getServerUrl() +
      'spec_calls/' +
      specId +
      '/feature_taggings/' +
      featureTagId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(''))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getFeatureTagsByIds(
    callData,
    featureIds: Array<number>,
    currency_id: number,
    multiplierData = null
  ) {
    let queryParams = 'currency_id=' + currency_id;
    if (!multiplierData) {
      queryParams += '&duration_multiplier=1';
    }

    if (multiplierData) {
      queryParams +=
        '&price_multiplier=' +
        (multiplierData.price_multiplier
          ? multiplierData.price_multiplier
          : 1) +
        '&duration_multiplier=' +
        (multiplierData.duration_multiplier
          ? multiplierData.duration_multiplier
          : 1) +
        '&custom_price_multiplier=' +
        (multiplierData.custom_price_multiplier
          ? multiplierData.custom_price_multiplier
          : 1) +
        '&user_price_multiplier=' +
        (multiplierData.user_price_multiplier
          ? multiplierData.user_price_multiplier
          : 1);
    }

    if (featureIds.length > 0) {
      queryParams += featureIds.reduce((acc, id) => {
        return acc + '&feature_ids[]=' + id;
      }, '');
    }
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      Constants.apiEndPoints.getFeaturesByIds +
      '?' +
      queryParams;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getDateAfterAddingWeeks(numWeeks) {
    const now = new Date();
    const latestDateNumber = now.setDate(now.getDate() + numWeeks * 7);
    const latestDate = new Date(latestDateNumber);
    return this.formatDate(latestDate);
  }

  public formatDate(latestDate) {
    const thedate = latestDate;
    const d = thedate,
      year = d.getFullYear();
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [day, month, year].join('/');
  }

  getBuildCardPricing(buildCardData) {
    let buildCardPrice = 0;
    if (
      buildCardData?.promotion?.data &&
      buildCardData?.bcard_price != buildCardData?.bcard_discounted_price
    ) {
      buildCardPrice = this.sharedService.decimalNumber(
        buildCardData.bcard_price ? buildCardData.bcard_price : 0
      );
    } else {
      buildCardPrice =
        buildCardData?.bcard_discounted_price ||
        buildCardData?.bcard_discounted_price === 0
          ? this.sharedService.decimalNumber(
              buildCardData?.bcard_discounted_price
            )
          : 0;
    }
    return buildCardPrice;
  }

  getBuildCardTimeline(buildCardData) {
    let buildCardTimeline = 0;
    if (
      buildCardData?.promotion?.data &&
      this.sharedService.decimalNumber(buildCardData?.actual_weeks) !=
        this.sharedService.ceilNumber(buildCardData?.installment_counts.weekly)
    ) {
      buildCardTimeline = this.sharedService.decimalNumber(
        buildCardData?.actual_weeks
      );
    } else if (
      !buildCardData?.promotion?.data &&
      buildCardData?.installment_counts.monthly > this.upperCustomerTimeline
    ) {
      buildCardTimeline = this.sharedService.ceilNumber(
        buildCardData?.actual_weeks - this.upperCustomerTimeline * 4
      );
    } else {
      buildCardTimeline =
        buildCardData?.installment_counts.weekly ||
        buildCardData?.installment_counts.weekly === 0
          ? this.sharedService.ceilNumber(
              buildCardData?.installment_counts.weekly
            )
          : null;
    }
    return this.getDateAfterAddingWeeks(
      this.sharedService.ceilNumber(buildCardTimeline)
    );
  }

  fetchBuilderCloudDetails(data) {
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'Authorization',
      environment.CLOUD_AUTH_TOKEN
    );
    const API = environment.CLOUD_API_URL + 'pricing';
    return this.httpRequest.post(API, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getDetailDocumentForSpec(projectId) {
    const url = `${this.dataService.getServerUrl()}spec_calls/${projectId}/doc_taggings`;
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  postDetailDocumentForSpec(projectId, fileId) {
    const url = `${this.dataService.getServerUrl()}spec_calls/${projectId}/doc_taggings`;
    const data = {
      document_id: fileId
    };
    return this.httpRequest
      .post(url, data, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public buildCardReadyToShare(buildcardId) {
    const url = `${this.dataService.getServerUrlForAccounts()}pm_dashboard/build_cards/${buildcardId}/ready_to_share`;
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );
    const data = {
      build_card: { is_ready_to_share: true }
    };
    return this.httpRequest.patch(url, data, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getMultiplierData(buildCardData) {
    let multiplierData;
    if (buildCardData) {
      multiplierData = {
        price_multiplier: buildCardData.multiplier_experiment?.data?.attributes
          ?.price_multiplier
          ? buildCardData.multiplier_experiment.data.attributes.price_multiplier
          : 1,
        duration_multiplier: buildCardData.multiplier_experiment?.data
          ?.attributes?.duration_multiplier
          ? buildCardData.multiplier_experiment.data.attributes
              .duration_multiplier
          : 1,
        custom_price_multiplier: buildCardData.multiplier_experiment?.data
          ?.attributes?.custom_price_multiplier
          ? buildCardData.multiplier_experiment.data.attributes
              .custom_price_multiplier
          : 1,
        user_price_multiplier: buildCardData.user_price_multiplier
          ? buildCardData.user_price_multiplier
          : 1
      };
    }

    return multiplierData;
  }

  public getBuildByIndex(index) {
    return this.dataService.getByIndex(
      this.configurationData.build_phases,
      index
    );
  }

  getTranscriptDataForSpec(specId) {
    const url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getSpecCallTranscriptsData.replace(
      '[spec_id]',
      specId
    )}`;
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getTranscriptStatusForSpec(specId) {
    const url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getSpecCallTranscriptsStatus.replace(
      '[spec_id]',
      specId
    )}`;
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  generateTranscriptForSpec(specId) {
    const url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.generateTranscriptForSpec.replace(
      '[spec_id]',
      specId
    )}`;
    return this.httpRequest
      .post(url, {}, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getFeaturePrice(featureObj) {
    const buildcardSelectedFeature = this.buildCardFeatures.find(
      (x) => x.attributes.feature_id == featureObj.id
    );
    if (buildcardSelectedFeature?.attributes) {
      return (
        (buildcardSelectedFeature.attributes.discounted_customisation
          ? buildcardSelectedFeature.attributes.discounted_customisation
          : buildcardSelectedFeature.attributes.customisation
          ? buildcardSelectedFeature.attributes.customisation
          : buildcardSelectedFeature.attributes.customisation_price
          ? buildcardSelectedFeature.attributes.customisation_price
          : 0) +
        (buildcardSelectedFeature.attributes.discounted_fixed
          ? buildcardSelectedFeature.attributes.discounted_fixed
          : buildcardSelectedFeature.attributes.fixed
          ? buildcardSelectedFeature.attributes.fixed
          : buildcardSelectedFeature.attributes.fixed_price
          ? buildcardSelectedFeature.attributes.fixed_price
          : 0)
      );
    } else {
      return featureObj.price;
    }
  }

  getFeatureDuration(featureObj) {
    const buildcardSelectedFeature = this.buildCardFeatures.find(
      (x) => x.attributes.feature_id == featureObj.id
    );
    if (buildcardSelectedFeature?.attributes) {
      return buildcardSelectedFeature.attributes.duration;
    } else {
      return featureObj.duration;
    }
  }

  getSimilarBuildCards(specOrProjectId?, payload?, isFromProject?) {
    let url = '';
    if (isFromProject) {
      url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getSimilarBuildCardForProject.replace(
        '[projectId]',
        specOrProjectId
      )}`;
      url = url + this.dataService.forceRedirect();
      return this.httpRequest
        .get(url, this.dataService.getRequestOptionArgs())
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((error) => {
            return throwError(() => error);
          })
        );
    } else {
      url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getSimilarBuildCard.replace(
        '[specCallId]',
        specOrProjectId
      )}`;
      return this.httpRequest
        .post(url, payload, this.dataService.getRequestOptionArgs())
        .pipe(
          map((res) => {
            return res;
          }),
          catchError((error) => {
            return throwError(() => error);
          })
        );
    }
  }

  pinBuildCard(project_id, payload) {
    const url = `${this.dataService.getServerUrl()}${Constants.apiEndPoints.getPinnedBuildCard.replace(
      '[projectId]',
      project_id
    )}`;
    return this.httpRequest
      .post(url, payload, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  deletePinBuildCards(project_id, projectId) {
    const url = (
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.deletePinnedBuildCard +
      +projectId
    ).replace('[projectId]', project_id);
    return this.httpRequest
      .delete(url, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public getProjectById(id, forceRedirect = false) {
    let API =
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.get_post +
      '/' +
      id;
    if (forceRedirect) {
      API += '?forceid=' + id;
    }
    return this.httpRequest
      .get(API, this.dataService.getRequestOptionArgs('application/json'))
      .pipe(
        map((res) => {
          if (res.data) {
            return res.data.project;
          }
          return null;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getSpecCallAttachments(specCallId: string) {
    const url = (
      this.dataService.getServerUrl() +
      Constants.apiEndPoints.getSpecCallAttachments
    ).replace('[spec_id]', specCallId);
    return this.httpRequest
      .get(url, this.dataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  getAssociatedBuildCard(buildcardId, callData) {
    const API =
      this.dataService.getServerUrlForAccounts(callData) +
      'pm_dashboard/build_card/' +
      buildcardId +
      '/associated_data';
    const httpOptions = { headers: new HttpHeaders() };
    httpOptions.headers = httpOptions.headers.set(
      'apptoken',
      this.dataService.getAppTokenForAccounts()
    );

    return this.httpRequest.get(API, httpOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  transferSpecCall(specId, data) {
    const API = this.dataService.getServerUrl() + 'spec_calls/' + specId;
    return this.httpRequest
      .patch(API, data, this.dataService.getRequestOptionArgs(''))
      .pipe(
        map((res) => {
          return res.data;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }
}
