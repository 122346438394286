import { Injectable } from "@angular/core";

@Injectable()
export class ProjectStatusService {
  public projectTabStatus;
  public openProDD = false;

  closeProjectDD() {
    this.openProDD = false;
  }

  openProjectDD() {
    this.openProDD = !this.openProDD;
  }
}
