export class Constants {
  static apiEndPoints = {
    getAllManager: 'admins',
    getPods: 'pods',
    inviteManager: 'admins/invite',
    timezone: 'timezones',
    reInviteUser: 'admins/reinvite_user',
    archiveManager: 'admins/[manager_id]/archive?value=',
    CRUDTribe: 'tribes',
    TribeCoverage: 'tribes/get_tribe_coverages',
    SquadTribeLocation: 'pods/[pod_id]/pod_tribe_locations',
    squadTimeline: 'pods/[pod_id]/timelines',
    tribeTimeline: 'tribes/[tribe_id]/timelines',
    memberTimeline: 'admins/[admins_id]/timelines',
    projects: 'projects'
  };
}

export class ManagerConstants {
  static departments = [
    { d_name: "Engineering", d_value: "engineering" },
    { d_name: "Finance", d_value: "finance" },
    { d_name: "Human Resources", d_value: "human_resources" },
    { d_name: "IT", d_value: "it" },
    { d_name: "Sales", d_value: "sales" },
    { d_name: "Marketing", d_value: "marketing" },
  ];

  static offices = [
    { o_name: "Gurgaon", o_value: "gurgaon" },
    { o_name: "San Francisco", o_value: "san_fransisco" },
    { o_name: "Los Angeles", o_value: "los_angeles" },
    { o_name: "London", o_value: "london" },
    { o_name: "Mauritius", o_value: "mauritius" },
    { o_name: "Amsterdam", o_value: "amsterdam" },
    { o_name: "Germany", o_value: "germany" },
    { o_name: "Kuala Lumpur", o_value: "kuala_lumpur" },
    { o_name: "Dubai", o_value: "dubai" },
    { o_name: "Singapore", o_value: "singapore" },
    { o_name: "Salt Lake City", o_value: "salt_lake_city" },
  ];
}

export class TimeSlotConstants {
  static list = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
  ];
}
