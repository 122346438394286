<div
  id="angular-custom-editor"
  class="angular-editor"
  [style.width]="config.width"
  [style.minWidth]="config.minWidth"
  [ngClass]="{ descriptionView: !isEditable, full_height: fullHeight }"
>
  <custom-angular-editor-toolbar
    *ngIf="config.toolbarPosition === 'top' && isEditable"
    #editorToolbar
    [id]="id"
    [uploadUrl]="config.uploadUrl"
    [upload]="config.upload"
    [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
    [fonts]="getFonts()"
    [customClasses]="config.customClasses"
    [defaultFontName]="config.defaultFontName"
    [defaultFontSize]="config.defaultFontSize"
    [hiddenButtons]="config.toolbarHiddenButtons"
    [isShowPasteBtn]="isShowPasteBtn"
    (execute)="executeCommand($event)"
  ></custom-angular-editor-toolbar>

  <div
    class="angular-editor-wrapper"
    [ngClass]="{ 'h-100': !isEditable }"
    #editorWrapper
  >
    <div
      #editor
      class="angular-editor-textarea"
      [ngClass]="{
        highLite_editable:
          !isEditable && componentName === 'project-feature-note'
      }"
      [attr.contenteditable]="config.editable"
      [attr.tabindex]="disabled ? -1 : tabIndex"
      [attr.translate]="config.translate"
      [attr.spellcheck]="config.spellcheck"
      [style.height]="config.height"
      [style.minHeight]="config.minHeight"
      [style.maxHeight]="config.maxHeight"
      [style.outline]="config.outline === false ? 'none' : undefined"
      (input)="onContentChange($event.target)"
      (focus)="onTextAreaFocus($event)"
      (blur)="onTextAreaBlur($event)"
      (click)="exec()"
      (keyup)="exec()"
      (mouseout)="onTextAreaMouseOut()"
    ></div>
    <span class="angular-editor-placeholder">{{
      placeholder || config["placeholder"]
    }}</span>
  </div>
  <custom-angular-editor-toolbar
    *ngIf="config.toolbarPosition === 'bottom' && isEditable"
    #editorToolbar
    [id]="id"
    [uploadUrl]="config.uploadUrl"
    [upload]="config.upload"
    [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
    [fonts]="getFonts()"
    [customClasses]="config.customClasses"
    [defaultFontName]="config.defaultFontName"
    [defaultFontSize]="config.defaultFontSize"
    [hiddenButtons]="config.toolbarHiddenButtons"
    [isShowPasteBtn]="isShowPasteBtn"
    (execute)="executeCommand($event)"
  ></custom-angular-editor-toolbar>
</div>
