<div class="initialLoader toasterwrap" *ngIf="showToasterMsg">
  <div class="loadingOverlay"></div>
  <ng-container *ngIf="getToastMessage$ | async as getToastMessage">
    <div
      class="project-success-msg"
      [ngClass]="{
        'project-error-msg': getToastMessage['errorClass'] === true
      }"
    >
      <img src="../../../../assets/images/toaster.svg" alt="" />
      <h3>
        {{ getToastMessage["toastMessage"] }}
      </h3>
      <a class="ms-auto" (click)="closeSaveTost()">
        <fa-icon icon="times" aria-hidden="true"></fa-icon>
      </a>
    </div>
  </ng-container>
</div>
