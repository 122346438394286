import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { LoaderService } from '../../../../core/services/loader.service';
import { ToastMessageService } from '../../../../core/services/toast.message.service';
import { DataService } from '../../services//data.service';
import { ApiRequests } from '../../services/apiRequests.service';

@Component({
  selector: 'app-link-google-calendar',
  templateUrl: './link-google-calendar.component.html',
  styleUrls: ['./link-google-calendar.component.css']
})
export class LinkGoogleCalendarComponent implements OnInit, OnDestroy {
  schedulerWindowRef;
  schedulerPostMessageListener;
  @Output() linkGoogleCalendarSuccess: EventEmitter<any> =
    new EventEmitter<any>();

  constructor(
    private loaderService: LoaderService,
    private apiRequest: ApiRequests,
    private toastMessageService: ToastMessageService,
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.schedulerPostMessageListener =
      this.receiveSchedulerWindowMessage.bind(this);
  }

  googleCalenderLogin() {
    const path = window.location.origin + "?auth_type=googlecalenderlogin";
    this.schedulerWindowRef = window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=${this.dataService.getGoogleClientId()}&include_granted_scopes=true&state=pass-throughvalue&prompt=consent&scope=https://www.googleapis.com/auth/calendar.events&redirect_uri=${path}`,
      "_blank",
      "height=740,width=740",
    );

    this.schedulerWindowRef.focus();
    window.addEventListener(
      "message",
      this.schedulerPostMessageListener,
      false,
    );
  }

  private receiveSchedulerWindowMessage(evt) {
    if (evt && evt.data === 'google_cal_auth_code') {
      if (this.dataService.user.google_cal_access_token === null) {
        const google_cal_auth_code = localStorage.getItem(
          "google_cal_auth_code",
        );
        localStorage.removeItem("google_cal_auth_code");
        if (google_cal_auth_code) {
          this.googelAccessToken(google_cal_auth_code);
        } else {
          const googleError = localStorage.getItem("google_cal_auth_error");
          localStorage.removeItem("google_cal_auth_error");
          if (googleError) {
            this.toastMessageService.showToastMessage(
              "Your Google Calendar is not configured properly. Please try again!",
              5000,
            );
          }
        }

        if (this.schedulerWindowRef) {
          window.removeEventListener(
            "message",
            this.schedulerPostMessageListener,
            false,
          );
          this.schedulerWindowRef = null;
        }
      }
    }
  }

  /**
   * googelAccessToken is a function
   * @description :  for getting using auth code
   * @param : string {authCode}
   */
  googelAccessToken(authCode) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    const path = window.location.origin + "?auth_type=googlecalenderlogin";
    this.http
      .post(
        `https://www.googleapis.com/oauth2/v4/token?code=${authCode}&client_id=${this.dataService.getGoogleClientId()}&client_secret=${this.dataService.getGoogleSecretkey()}&redirect_uri=${path}&grant_type=authorization_code`,
        JSON.stringify({}),
        httpOptions,
      )
      .subscribe(
        (res) => {
          if (res['access_token']) {
            this.googleUpdate(
              res['access_token'],
              res['refresh_token'],
              res['expires_in'],
              res['scope'],
              res['token_type']
            );
          } else {
            this.toastMessageService.showToastMessage(
              "Your Google Calendar is not configured properly. Please try again!",
              5000,
              true,
            );
          }
        },
        () => {
          this.toastMessageService.showToastMessage(
            "Your Google Calendar is not configured properly. Please try again!",
            5000,
            true,
          );
        },
      );
  }

  /**
   * zoomUpdate is a function.
   * @description : Send authorization code to backend
   * @param {string} accesstoken - authorization code
   * @param { string} refreshtoken - refesh token
   * @param { string} expire time
   * @param { string} scope allowed
   * @param { string} token_type type of token
   *
   */
  googleUpdate(
    accesstoken: string,
    refreshtoken: string,
    expiretime,
    scope: string,
    token_type: string,
  ) {
    this.loaderService.show();
    // :google_cal_access_token, :google_cal_expires_in, :google_cal_scope, :google_cal_token_type, :google_cal_refresh_token
    const googleObj = {
      google_cal_access_token: accesstoken,
      google_cal_refresh_token: refreshtoken ? refreshtoken : "",
      google_cal_expires_in: expiretime,
      google_cal_scope: scope,
      google_cal_token_type: token_type
    };
    this.apiRequest.updateUserDetails(googleObj).subscribe(
      (data) => {
        if (data.message === 'success') {
          localStorage.setItem('user', JSON.stringify(this.dataService.user));
          this.toastMessageService.showToastMessage(
            "Your Google Calendar has been linked successfully.",
            5000,
          );
          this.linkGoogleCalendarSuccess.emit(null);
        } else {
          if (data.message === "failed") {
            this.toastMessageService.showToastMessage(
              "Your Google Calendar is not configured properly. Please try again!",
              5000,
              true,
            );
          }
        }
        this.loaderService.hide();
      },
      () => {
        this.toastMessageService.showToastMessage(
          "Your Google Calendar is not configured properly. Please try again!",
          5000,
          true,
        );
        this.loaderService.hide();
      },
    );
  }

  ngOnDestroy(): void {
    if (this.schedulerWindowRef) {
      window.removeEventListener(
        "message",
        this.schedulerPostMessageListener,
        false,
      );
    }
  }
}
