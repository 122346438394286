<div
  bsModal
  #callScore="bs-modal"
  class="client-modal call-score-modal modal fade"
  id="callScore"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalCenterTitle"
  aria-hidden="true"
  (onHide)="popupHide()"
  (onShow)="popupShow()"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content transparent-modal">
      <div class="modal-body">
        <div class="call-score">
          <div class="close-circle" (click)="hideCallPop()">
            <img
              src="assets/images/cross-call-score.svg"
              alt="cross-call"
              width="10"
              height="12"
            />
          </div>
          <div class="top-head-score">
            <h3>Call Score</h3>
            <p>
              Spare a minute and share your feedback on the past calls taken by
              you.
            </p>
          </div>
          <div class="score-info-box">
            <div class="score-info-item">
              <div class="call-text">Call Details</div>
            </div>
            <div class="score-info-item">
              <div
                class="call-with text-truncate"
                title="{{ getCurrentInfo?.client_name }} with {{
                  getCurrentInfo?.assigned_name
                }}"
              >
                {{ getCurrentInfo?.client_name }} <span>With</span>
                {{ getCurrentInfo?.assigned_name }}
              </div>
              <div class="pager-info">
                {{ currentScoreData }}/{{ callScoreData?.length }} Calls
              </div>
            </div>
            <div class="score-info-item">
              <div class="score-date">
                {{ getCurrentInfo?.schedule_date | date }}
              </div>
              <div class="pager-next-previous">
                <div
                  class="prev pager-item"
                  [ngClass]="{ 'hide-on': currentScoreData === 1 }"
                  (click)="prevData()"
                >
                  <img
                    src="assets/images/arrow-button-circle-right-1.svg"
                    alt="arrow-button"
                    width="16"
                    height="16"
                  />
                  <span>Previous</span>
                </div>
                <div
                  class="next pager-item"
                  [ngClass]="{
                    'hide-on': currentScoreData === callScoreData.length
                  }"
                  (click)="nextData()"
                >
                  <span>Next</span>
                  <img
                    src="assets/images/arrow-button-circle-right-2.svg"
                    alt="arrow-button"
                    width="16"
                    height="16"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="call-score-update-wrp">
            <div class="call-score-update-main">
              <div
                class="score-item"
                *ngFor="
                  let scoresItem of callScoresItem;
                  let current_item_index = index
                "
                [ngClass]="{ active: currentScoreIndex === current_item_index }"
              >
                <ng-template
                  [ngIf]="checkArray(scoresItem.call_value)"
                  [ngIfElse]="singleScore"
                >
                  <div class="round-number no-cursor">
                    {{ scoresItem.call_num }}
                  </div>
                  <div class="score-label-wrp">
                    <div
                      class="score-label"
                      *ngFor="let call_value of scoresItem.call_value"
                      (click)="selectCurrentScore(current_item_index)"
                    >
                      <label>
                        <input
                          name="score_value"
                          type="radio"
                          [value]="scoresItem.call_num + ',' + call_value"
                          [(ngModel)]="selectedRating"
                          (ngModelChange)="onChangeRating($event)"
                        />
                        <span>{{ call_value }}</span>
                      </label>
                    </div>
                  </div>
                </ng-template>
                <ng-template #singleScore>
                  <div
                    class="round-number"
                    (click)="
                      selectCurrentScore(
                        current_item_index,
                        scoresItem.call_num + ',' + scoresItem.call_value
                      )
                    "
                  >
                    {{ scoresItem.call_num }}
                  </div>
                  <div class="score-label-wrp">
                    <div
                      class="score-label no-radio-ui"
                      (click)="selectCurrentScore(current_item_index)"
                    >
                      <label>
                        <input
                          name="score_value"
                          type="radio"
                          [value]="
                            scoresItem.call_num + ',' + scoresItem.call_value
                          "
                          [(ngModel)]="selectedRating"
                          (ngModelChange)="onChangeRating($event)"
                        />
                        {{ scoresItem.call_value }}
                      </label>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="score-item-comment">
              <div class="feedback-heading">Comments/Feedback</div>
              <textarea
                id="feedback"
                placeholder="Write your comments /feedback here"
                [(ngModel)]="selectedFeedback"
                (ngModelChange)="onChangeFeedback($event)"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- {{scoreToSend | json}} -->
        <div class="d-flex justify-content-end call-score-btn">
          <input
            class="save-btn"
            type="button"
            [disabled]="!selectedSaveBtn"
            (click)="saveData()"
            value="Save Changes"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="initialLoader" *ngIf="isLoader">
  <div class="homeLoader">
    <img
      src="assets/images/builder_black.png"
      alt="builder-loader"
      width="64"
      height="64"
    />
  </div>
  <div class="loadingOverlay"></div>
</div>
