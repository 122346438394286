import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "../../../shared/shared-module/services/data.service";

@Component({
  selector: "app-under-maintainance",
  templateUrl: "./under-maintainance.component.html",
})
export class UnderMaintainanceComponent {
  constructor(
    public router: Router,
    public dataService: DataService,
  ) {}

  homePage() {
    this.router.navigate(["/projects"]);
  }
}
