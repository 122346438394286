// when need to trigger a function ( such as in case of while searching ) but only once, the default timing is 800ms
// but we can pass any number of seconds we want as per the requirement
// one such example can be seen in add-edit-pod.component.ts
// searchChange = debounce((event) => this.onKeydownEvent(event));

import { FormControl } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import sign from 'jwt-encode';

// and we are calling searchChange from the template file
export function debounce(func, timeout = 800) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function throttle(func, delay = 800) {
  let timeout = null;
  return (...args) => {
    if (!timeout) {
      func.apply(this, args);
      timeout = setTimeout(() => {
        timeout = null;
      }, delay);
    }
  };
}

// search for string in object
const { isArray } = Array;
export function search(entries, search: string) {
  search = search.toLowerCase();

  return entries.filter(function (obj) {
    const keys: string[] = Object.keys(obj);
    return keys.some(function (key) {
      const value = obj[key];
      if (isArray(value)) {
        return value.some((v) => {
          return v
            ? typeof v === "string"
              ? v.toLowerCase().includes(search)
              : v.toString().toLowerCase().includes(search)
            : "";
        });
      } else if (!isArray(value)) {
        return value
          ? typeof value === "string"
            ? value.toLowerCase().includes(search)
            : value.toString().toLowerCase().includes(search)
          : "";
      }
    });
  });
}

export function getNameAndInitial(string) {
  if (string) {
    const names = string.split(' '),
      fullname = string;
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return {
      initials,
      // name: `${names[0]} ${names[names.length - 1]}.`,
      name: fullname.toUpperCase(),
      short_name:
        fullname.length > 15
          ? `${fullname.slice(0, 13).toUpperCase()}...`
          : fullname.toUpperCase()
    };
  } else {
    return "";
  }
}

export class CustomValidators {
  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static emailVaildator(control: FormControl) {
    const email = control.value;
    const re =
      /* eslint-disable-next-line */
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) ? null : { email: true };
  }
}

export function compareTwoStrings(first, second) {
  first = first.replace(/\s+/g, "");
  second = second.replace(/\s+/g, "");

  if (first === second) return 1; // identical or empty
  if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

  const firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

export function jsonwebtokenDecrypt(tokenizedValue: string) {
  try {
    const decrypted = jwt_decode(tokenizedValue);
    return decrypted;
  } catch (err) {
    return err;
  }
}

export function jsonwebtokenEncrypt(payload, secretKey: string) {
 try {
  return sign(payload, secretKey);
 } catch(err) {
  return err;
 }
}
