import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { AuthGuard } from '../../../auth-guard.service';
import { MeetingSharedService } from '../../../core/meeting-shared-module/service/meeting-shared.service';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { CommonService } from '../../../shared/shared-module/services/common.service';
import { DataService } from '../../../shared/shared-module/services/data.service';
import { DynamicLoaderService } from '../../../shared/shared-module/services/dynamic-lazy-loader.service';
import { LocalForageService } from '../../../shared/shared-module/services/local-forage.service';
import { Constants } from '../../../shared/shared-module/utility/constants';
import { LoaderService } from '../../services/loader.service';
import { ProjectStatusService } from '../../services/project-status.service';
import { ToastMessageService } from '../../services/toast.message.service';
import { CanComponentDeactivate } from '../../../../app/hasunsavedchanges.guard';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  public currentSelection;
  public leftPanel;
  public headerLogoUrl = '';
  public userData;
  public statusShowHide = false;
  public currentThemeIndex = 0;
  myTaskShow = false;
  isShowHeaderDropDown = false;
  public reasonType;
  public statusArray = [];
  public selectedIndex = 0;
  subscription = new Subject();
  myProjectDetailSubscription;
  isStudio4BuildCard = false;
  myMeetingsSidebar = false;
  public reasonArray = [
    { name: 'Meeting ended early', value: 'Meeting ended early' },
    { name: 'Customer dropped off', value: 'Customer dropped off' }
  ];
  public themeData = [
    {
      colorValue: '#4fa399',
      colorText: 'green'
    },
    {
      colorValue: '#618dcc',
      colorText: 'blue'
    },
    {
      colorValue: '#ad80d1',
      colorText: 'purple'
    },
    {
      colorValue: '#8ace8a',
      colorText: 'lightGreen'
    },
    {
      colorValue: '#8ea0a8',
      colorText: 'grey'
    },
    {
      colorValue: '#5688a1',
      colorText: 'softBlue'
    }
  ];
  @ViewChild('SpecStatusModal', { static: true })
  SpecStatusModal: ModalDirective;
  activatedComponent: CanComponentDeactivate;
  constructor(
    public dataService: DataService,
    public toastMessageService: ToastMessageService,
    public router: Router,
    public commonService: CommonService,
    public renderer: Renderer2,
    public loaderService: LoaderService,
    public apiRequests: ApiRequests,
    public projectStatusService: ProjectStatusService,
    public authGuard: AuthGuard,
    private dynamicLoaderService: DynamicLoaderService,
    private meetingSharedService: MeetingSharedService,
    private localForageService: LocalForageService,
    private activatedRoute: ActivatedRoute
  ) {}
  public showSpecStatusModal() {
    this.selectedIndex = 0;
    this.reasonType = '';
    this.SpecStatusModal.show();
  }

  public closeSpecStatusModal() {
    this.reasonType = '';
    this.commonService.currentStatus = this.dataService.user.meeting_status;
    this.SpecStatusModal.hide();
  }
  ngOnInit() {
    this.router.events
      .pipe(
        takeUntil(this.subscription),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.activatedComponent = this.getChildRouteComponent(
          this.activatedRoute
        );
      });

    this.myProjectDetailSubscription =
      this.commonService.notifyProjectDetailSubject.subscribe((data) => {
        if (data) {
          if (data?.studio_version == 2) {
            this.isStudio4BuildCard = true;
          } else {
            this.isStudio4BuildCard = false;
          }
        }
      });
    if (this.dataService?.user?.id) {
      this.apiRequests.getUpdatedUserData().subscribe((user) => {
        // localStorage.setItem('user', JSON.stringify(this.dataService.user));
        this.commonService.currentStatus = this.dataService.user.meeting_status;
      });
    }
    this.commonService.notifyMyAccountSubject.subscribe(() => {
      this.userData = this.dataService.getUserData();
      const statusArray = this.dataService.user
        ? this.dataService.user.all_meetings_status
        : '';
      this.statusArray = statusArray ? Object.keys(statusArray) : [];
      this.commonService.currentStatus = this.dataService.user
        ? this.dataService.user.meeting_status
        : 'Not Available';
      if (this.userData) {
        this.themeData.forEach((color, index) => {
          if (color.colorValue === this.dataService.user.theme_code) {
            this.removeAllClassesFromBody();
            this.currentThemeIndex = index;
            this.renderer.addClass(document.body, color.colorText);
          }
        });
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        this.currentSelection = url;
      }
    });

    this.meetingSharedService.closeMyMeeting.subscribe((data) => {
      if (data) {
        this.myMeetingsSidebar = false;
      }
    });
  }

  toggleHeaderDropDown(event: Event) {
    event.stopPropagation();
    this.isShowHeaderDropDown = this.isShowHeaderDropDown ? false : true;
    document.querySelector('body').addEventListener('click', () => {
      this.isShowHeaderDropDown = false;
    });
    this.myTaskShow = false;
  }

  toggleHeaderTask(event: Event) {
    event.stopPropagation();
    this.myMeetingsSidebar = false;
    this.myTaskShow = this.myTaskShow ? false : true;
    document.querySelector('body').addEventListener('click', () => {
      this.myTaskShow = false;
    });
    this.isShowHeaderDropDown = false;
  }

  logout() {
    if (
      !this.activatedComponent ||
      !this.commonService.activateComponent ||
      !this.commonService.activateComponent.canDeactivate ||
      (this.activatedComponent &&
        this.commonService.activateComponent?.constructor?.name ===
          this.activatedComponent['name'] &&
        this.commonService.activateComponent.canDeactivate &&
        this.commonService.activateComponent.canDeactivate())
    ) {
      this.loaderService.show();
      this.apiRequests
        .logoutSession()
        .pipe(takeUntil(this.subscription), take(1))
        .subscribe(
          async (response) => {
            if (response.message === 'success') {
              this.commonService.newHeadertrue = true;
              this.projectStatusService.projectTabStatus = '';
              this.dataService.user = null;
              this.dataService.authToken = null;
              localStorage.setItem('user', '');
              localStorage.setItem('authToken', '');
              localStorage.setItem('clickedIds', JSON.stringify([]));
              localStorage.removeItem('projectAllocationTab');
              this.router.navigate(['/login']);
              this.authGuard.inputedURL = '';
              this.removeAllClassesFromBody();
              try {
                await this.localForageService.clearStore();
              } catch (error) {
                console.error('Error clearing localForage store:', error);
              }
            }
            this.loaderService.hide();
          },
          () => {
            this.toastMessageService.showToastMessage(
              Constants.toastMessages.error,
              3000,
              true
            );
            this.loaderService.hide();
          }
        );
    }
  }

  goToProjects() {
    if (this.router.url === '/projects') {
      return;
    }
    if (this.checkUserLogged()) {
      this.loaderService.show();
      this.router.navigate(['/projects']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  navigationHandlingInNoteEditMode(event?, clickOption?) {
    if (
      confirm('You may have unsaved changes, are you sure you want to exit ?')
    ) {
      this.commonService.resetFeatureNotes.next(true);
      if (clickOption === 'myTask') {
        this.toggleHeaderTask(event);
      } else if (event === 'project') {
        this.goToProjects();
      } else if (clickOption === 'status') {
        this.toggleHeaderDropDown(event);
      }
      return true;
    }
  }

  checkUserLogged() {
    return this.dataService.user;
  }

  removeAllClassesFromBody(): void {
    this.renderer.removeClass(document.body, 'green');
    this.renderer.removeClass(document.body, 'blue');
    this.renderer.removeClass(document.body, 'purple');
    this.renderer.removeClass(document.body, 'lightGreen');
    this.renderer.removeClass(document.body, 'grey');
    this.renderer.removeClass(document.body, 'softBlue');
  }

  statusShow(type, event) {
    this.viewStatus(type, event);
  }

  viewStatus(type, event: Event) {
    event.stopPropagation();
    this.statusShowHide = type ? false : true;
    document.querySelector('body').addEventListener('click', () => {
      this.statusShowHide = false;
    });
  }

  statusUpdate(type) {
    this.loaderService.show();
    this.commonService.currentStatus = type;
    this.statusShowHide = false;
    const statusObj = {
      meeting_status: this.commonService.currentStatus
    };
    this.apiRequests.updateUserDetails(statusObj).subscribe(
      (data) => {
        if (data.message === 'success') {
          this.dataService.user.meeting_status = data.data.user.meeting_status;
          localStorage.setItem('user', JSON.stringify(this.dataService.user));
          this.loaderService.hide();
        }
      },
      (error) => {
        if (error.error.skip_spec_overlap) {
          this.showSpecStatusModal();
          this.loaderService.hide();
        } else {
          this.toastMessageService.showToastMessage(error.error);
          this.commonService.currentStatus =
            this.dataService.user.meeting_status;
          this.SpecStatusModal.hide();
          this.loaderService.hide();
        }
      }
    );
  }

  temp(type, index) {
    this.selectedIndex = index;
    if (this.selectedIndex === 0) {
      this.reasonType = type;
    } else if (this.selectedIndex === 1) {
      this.reasonType = type;
    } else {
      this.reasonType = '';
    }
  }

  confirmStatusChange() {
    this.loaderService.show();
    if (this.reasonType === '') {
      this.reasonType = 'Meeting ended early';
    }
    this.statusShowHide = false;
    const data = {
      user: {
        meeting_status: this.commonService.currentStatus,
        skip_spec_overlap: true,
        spec_reason: this.reasonType
      }
    };
    this.apiRequests.updateUserDetails(data).subscribe((data) => {
      if (data.message === 'success') {
        this.dataService.user.skip_spec_overlap =
          data.data.user.skip_spec_overlap;
        this.dataService.user.meeting_status = data.data.user.meeting_status;
        this.dataService.user.spec_reason = data.data.user.spec_reason;
        localStorage.setItem('user', JSON.stringify(this.dataService.user));
        this.SpecStatusModal.hide();
        this.loaderService.hide();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.next(null);
    this.subscription.complete();
  }

  navigationHandlingInJourneyNoteEditMode(event, navigateTo?) {
    if (
      confirm('You may have unsaved changes, are you sure you want to exit ?')
    ) {
      if (this.isStudio4BuildCard) {
        this.commonService.resetJourneyNotes.next(true);
      } else {
        this.commonService.resetFeatureNotes.next(true);
      }
      if (navigateTo === 'dropDown') {
        this.toggleHeaderDropDown(event);
      } else if (navigateTo === 'task') {
        this.toggleHeaderTask(event);
      } else if (event === 'project') {
        this.goToProjects();
      }
      return true;
    }
  }

  openMyMeeting() {
    this.myMeetingsSidebar = !this.myMeetingsSidebar;
    if (this.myMeetingsSidebar)
      this.dynamicLoaderService.loadLazyComponent(
        this.viewContainerRef,
        'MyMeetingsSidebarComponent'
      );
  }

  getChildRouteComponent(route: ActivatedRoute): CanComponentDeactivate {
    let activeRoute = route;

    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }

    return activeRoute.snapshot.component as unknown as CanComponentDeactivate;
  }
}
