export enum LoaderActionTypes {
  MAIN_LOAD = "[LOADER] Set",
  PAGINATION_LOAD = "LOADER",
  AUTHTOKEN_UPDATE = "[AUTH TOKEN] Update",
}

//Set
export class SetIsLoaderEnable {
  static readonly type = LoaderActionTypes.MAIN_LOAD;
  constructor(public payload: boolean) {}
}

export class IsAuthTokeUpdated {
  static readonly type = LoaderActionTypes.AUTHTOKEN_UPDATE;
  constructor(public payload: boolean) {}
}
