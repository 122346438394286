import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { TribeService } from '../../services/tribe.service';
import {
  AddTribe,
  GetAllTribe,
  GetTribe,
  GetTribeCoverage,
  GetTribeManager,
  GetTribeServiceRegions,
  GetTribeSquad,
  SetFilterQuery,
  SetGroup,
  SetSearch,
  SetViewType,
  UpdateTribe
} from './tribe.action';
import { TribeModel } from './tribe.model';

@State<TribeModel>({
  name: 'tribe_state',
  defaults: {
    type: 'view',
    entities: {},
    loading: false,
    tribeList: [],
    tribe: {},
    groups: {},
    next_page: 0,
    per_page: 0,
    total_count: 0,
    total_pages: 0,
    error: {},
    tribeCoverage: [],
    tribeManager: [],
    tribeManagerNextPage: 0,
    tribeManagerPerPage: 0,
    tribeSquad: [],
    view_type: 'grid',
    filter_query: {
      status: [],
      coverage: [],
      head: [],
      leader: [],
      service_region: []
    },
    searchText: '',
    tribeForSquad: [],
    loadingTribe: false
  }
})
@Injectable()
export class TribeState {
  tribeCoverageData;
  tribeManagerData;
  tribeSquadData = [];
  tribeList = [];
  defaultAllTribe = [];
  tribeManagerNextPage = 1;
  tribeManagerPerPage = 15;
  constructor(private tribeService: TribeService) {}

  @Action(SetViewType)
  setViewType(
    { getState, setState }: StateContext<any>,
    { payload }: SetViewType
  ) {
    setState({
      ...getState(),
      view_type: payload
    });
  }

  @Action(SetFilterQuery)
  setFilterQuery(
    { getState, setState }: StateContext<any>,
    { payload }: SetFilterQuery
  ) {
    setState({
      ...getState(),
      filter_query: payload
    });
  }

  @Action(SetGroup)
  setGroup({ getState, setState }: StateContext<any>, { payload }: SetGroup) {
    setState({
      ...getState(),
      groups: payload
    });
  }

  @Action(SetSearch)
  setSearch({ getState, setState }: StateContext<any>, { payload }: SetSearch) {
    setState({
      ...getState(),
      searchText: payload
    });
  }

  @Action(GetAllTribe)
  getAllDataFromState(
    ctx: StateContext<TribeModel>,
    { page, perPage, searchText, status, leader, service_region }
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true, loadingTribe: true });
    return this.tribeService
      .getAllTribe(
        page,
        perPage,
        searchText,
        status,
        // coverage,
        leader,
        service_region
      )
      .pipe(
        tap(
          (returnData) => {
            if (!searchText) {
              this.defaultAllTribe =
                returnData && returnData['message'] === 'success'
                  ? returnData['data']?.tribes
                  : [];
            }
            this.tribeList =
              returnData && returnData['message'] === 'success'
                ? returnData['data']?.tribes
                : [];

            ctx.setState({
              ...state,
              type: 'view',
              entities:
                returnData && returnData['message'] === 'success'
                  ? returnData['data']
                  : null,
              loading: false,
              loadingTribe: false,
              tribeList:
                returnData['data']?.tribes.length > 0
                  ? returnData['data']?.tribes
                  : [],
              groups: returnData['data']?.groups,
              tribeCoverage: this.tribeCoverageData,
              total_count:
                returnData && returnData['message'] === 'success'
                  ? returnData['data'].total_count
                  : 0,
              tribeForSquad: this.defaultAllTribe as any
            });
          },
          (err) => {
            ctx.setState({
              ...state,
              loading: false,
              error: err,
              loadingTribe: false
            });
          }
        )
      );
  }

  @Action(AddTribe)
  addDataToState(ctx: StateContext<any>, { payload }: AddTribe) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.tribeService.addTribes(payload).pipe(
      tap(
        (returnData) => {
          if (
            returnData['message'] === 'success' &&
            state.tribeList.length > 0
          ) {
            state.tribeList.push(returnData['tribe']);
            returnData['data']['apiType'] = 'add';
          }

          ctx.setState({
            ...state,
            type: 'save',
            entities:
              returnData && returnData['message'] === 'success'
                ? returnData['data']
                : null,
            loading: false
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  @Action(UpdateTribe)
  updateDataOfState(ctx: StateContext<any>, { payload, id }: UpdateTribe) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.tribeService.updateTribe(payload, id).pipe(
      tap(
        (returnData) => {
          if (returnData['message'] === 'success') {
            const index = state.tribeList.map(({ id }) => id).indexOf(id);
            state.tribeList[index] = returnData['data'].tribe;
          }

          ctx.setState({
            ...state,
            type: 'edit',
            entities:
              returnData && returnData['message'] === 'success'
                ? returnData['data'].tribe
                : null,
            loading: false
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  @Action(GetTribe)
  getDataFromState(ctx: StateContext<any>, { id }: UpdateTribe) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.tribeService.getTribeById(id).pipe(
      tap(
        (returnData) => {
          ctx.setState({
            ...state,
            type: 'view',
            tribe:
              returnData && returnData['message'] === 'success'
                ? returnData['data'].tribe
                : null,
            loading: false,
            tribeList: this.tribeList.length > 0 ? this.tribeList : [],
            tribeCoverage: this.tribeCoverageData,
            total_count: this.tribeList.length,
            tribeForSquad: this.defaultAllTribe as any
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  @Action(GetTribeCoverage)
  getTribeCoverage(ctx: StateContext<any>) {
    const state = ctx.getState();
    ctx.setState({ ...state, loading: true });
    return this.tribeService.getTribeCoverage().pipe(
      tap(
        (returnData) => {
          this.tribeCoverageData =
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : null;
          ctx.setState({
            ...state,
            tribeCoverage:
              returnData && returnData['message'] === 'success'
                ? returnData['data']
                : null,
            loading: false,
            tribeList: this.tribeList.length > 0 ? this.tribeList : [],
            total_count: this.tribeList.length,
            tribeForSquad: this.defaultAllTribe as any
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }

  @Action(GetTribeManager)
  GetTribeManager(
    ctx: StateContext<any>,
    { type, page, perPage, searchText, value, filterStr, min }
  ) {
    const state = ctx.getState();
    ctx.patchState({ ...state, loading: true });
    if (
      type &&
      state?.tribeManager?.type &&
      type !== state?.tribeManager?.type
    ) {
      page = 1;
    }
    return this.tribeService
      .getAllManager(page, perPage, searchText, value, filterStr, min)
      .pipe(
        tap(
          (returnData) => {
            if (page == 1 || searchText) {
              ctx.patchState(
                this.reAssignManagerList(type, ctx.getState(), returnData)
              );
            } else {
              ctx.patchState(
                this.reAssignManagerList(type, ctx.getState(), returnData, true)
              );
            }
          },
          (err) => {
            ctx.patchState({ ...state, loading: false, error: err });
          }
        )
      );
  }

  reAssignManagerList(type, state, returnData, isConcat?) {
    const isContactData = isConcat
      ? {
          type: type,
          user:
            state.tribeManager?.user?.length > 0
              ? state.tribeManager.user.concat(returnData['data']?.users)
              : returnData['data']?.users
        }
      : { user: returnData['data']?.users, type: type };
    this.tribeManagerData =
      returnData &&
      returnData['message'] === 'success' &&
      returnData['data']?.users?.length > 0
        ? isContactData
        : { user: [], type: type };
    this.tribeManagerNextPage = returnData['data']?.next_page;
    this.tribeManagerPerPage = returnData['data']?.per_page;
    return {
      ...state,
      tribeManager: this.tribeManagerData,
      loading: false,
      tribeList: this.tribeList.length > 0 ? this.tribeList : [],
      tribeCoverage: this.tribeCoverageData,
      tribeManagerNextPage: returnData['data']?.next_page,
      tribeManagerPerPage: returnData['data']?.per_page,
      total_count: this.tribeList.length > 0 ? this.tribeList.length : 0,
      tribeForSquad: this.defaultAllTribe as any
    };
  }

  @Action(GetTribeSquad)
  getTribeSquad(ctx: StateContext<TribeModel>, { page, perPage }) {
    const state = ctx.getState();
    ctx.setState({ ...state });
    return this.tribeService.getTribeSquad().pipe(
      tap((returnData) => {
        this.tribeSquadData =
          returnData && returnData['message'] === 'success'
            ? returnData['data'].pods
            : [];
        ctx.patchState({
          ...state,
          tribeSquad:
            returnData && returnData['message'] === 'success'
              ? returnData['data'].pods
              : null,
          total_count: this.tribeList.length,
          tribeList: this.tribeList.length > 0 ? this.tribeList : [],
          tribeCoverage: this.tribeCoverageData,
          tribeManager: this.tribeManagerData,
          tribeManagerNextPage: this.tribeManagerNextPage,
          tribeManagerPerPage: this.tribeManagerPerPage,
          tribeForSquad: this.defaultAllTribe as any
        });
      })
    );
  }

  @Action(GetTribeServiceRegions)
  getServiceRegions(ctx: StateContext<TribeModel>) {
    const state = ctx.getState();
    ctx.patchState({
      loading: true
    });
    return this.tribeService.getServiceRegions().pipe(
      tap(
        (returnData) => {
          this.tribeCoverageData =
            returnData && returnData['message'] === 'success'
              ? returnData['data']
              : null;
          ctx.setState({
            ...state,
            tribeCoverage:
              returnData && returnData['message'] === 'success'
                ? returnData['data']
                : null,
            loading: false,
            tribeList: this.tribeList.length > 0 ? this.tribeList : [],
            total_count: this.tribeList.length,
            tribeForSquad: this.defaultAllTribe as any
          });
        },
        (err) => {
          ctx.setState({ ...state, loading: false, error: err });
        }
      )
    );
  }
}
