<footer
  class="footer"
  [ngClass]="{
    expandFooter: footerExpand
  }"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <p>copyright &copy; Builder.ai {{ currentYear }}</p>
      </div>
      <div class="col-md-8">
        <ul class="footer-links">
          <!--Opens mattermost chat-->
          <!-- <li
            class="trackerList dropup"
            *ngIf="openLeftPanel() && mattermost_url"
          >
            <div class="trackerhasList" (click)="goToMattermost()">
              <img
                src="assets/images/mattermost_logo_horizontal.svg"
                alt="mattermost"
                width="91px"
                height="14px"
              />
            </div>
          </li> -->

          <li
            class="trackerList dropup"
            *ngIf="
              dataService.partnerData?.code === 'e-ai' ||
              dataService.partnerData?.code === 'liquidstudio'
            "
          >
            <ng-container
              *ngIf="
                openLeftPanel() && builderPlanners.length > 0;
                else oldView
              "
            >
              <div
                id="tracker_toggle"
                class="trackerhasList"
                (click)="toggleTrackerList($event, 'tracker')"
              >
                <img
                  src="assets/images/revamp-buildertracker-logo.svg"
                  alt="tracker"
                  width="91"
                  height="14"
                />
                <em class="zmdi zmdi-chevron-up"></em>
              </div>
              <div
                class="dropdown-menu show"
                data-popper-placement="top-start"
                *ngIf="isShowTrackerList"
                (click)="$event.stopPropagation()"
              >
                <div class="dropdownMenuInner">
                  <div class="triangle">
                    <div
                      class="item-status-manager"
                      title="{{ list.board_name }}"
                      (click)="openTrackerLink(list.url)"
                      *ngFor="let list of builderPlanners; index as i"
                    >
                      <div class="nameAndPlat text-truncate">
                        ({{
                          list.project_platform === 'macos'
                            ? 'macOS'
                            : list.project_platform === 'ios'
                              ? 'iOS'
                              : list.project_platform === 'watchos'
                                ? 'watchOS'
                                : (list.project_platform | titlecase)
                        }}) {{ list.board_name }}
                      </div>
                      <span class="text-truncate">{{ list.url }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #oldView>
              <a (click)="naviagteToTracker()" rel="noopener noreferrer"
                ><img
                  src="assets/images/revamp-buildertracker-logo.svg"
                  alt="tracker"
                  width="91"
                  height="14"
              /></a>
            </ng-template>
          </li>
          <li
            class="trackerList dropup"
            *ngIf="openLeftPanel() && builderPlanners.length > 0"
          >
            <div
              class="trackerhasList"
              (click)="toggleTrackerList($event, 'uiw')"
            >
              <img
                src="assets/images/builder-uiw.svg"
                alt="builder"
                width="91"
                height="14"
              />
              <em class="zmdi zmdi-chevron-up"></em>
            </div>
            <div
              class="dropdown-menu show"
              *ngIf="isShowUiwList"
              (click)="$event.stopPropagation()"
            >
              <div class="dropdownMenuInner">
                <div class="triangle">
                  <div
                    class="item-status-manager"
                    title="{{ list.board_name }}"
                    (click)="openTrackerLink(list.url, true)"
                    *ngFor="let list of builderPlanners; index as i"
                  >
                    <div class="nameAndPlat text-truncate">
                      ({{
                        list.project_platform === 'macos'
                          ? 'macOS'
                          : list.project_platform === 'ios'
                            ? 'iOS'
                            : list.project_platform === 'watchos'
                              ? 'watchOS'
                              : (list.project_platform | titlecase)
                      }}) {{ list.board_name }}
                    </div>
                    <span class="text-truncate">{{
                      list.url + '?redirect_to_uiw=true'
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="trackerList dropup" *ngIf="openLeftPanel() && uniCode">
            <div class="trackerhasList" (click)="openTrackerLink()">
              <img
                src="assets/images/Builder_home.svg"
                alt="home"
                width="91"
                height="14"
              />
            </div>
          </li>

          <li class="trackerList dropup" *ngIf="openLeftPanel()">
            <div class="trackerhasList" (click)="openBNowLink()">
              <img
                src="assets/images/b-now-logo.svg"
                alt="b-now"
                width="91"
                height="14"
              />
            </div>
          </li>
        </ul>
      </div>

      <div class="footerExpand d-md-none" (click)="mobileFooter()">
        <em class="zmdi zmdi-chevron-up" *ngIf="footerExpand"></em>
        <em class="zmdi zmdi-chevron-down" *ngIf="!footerExpand"></em>
      </div>
    </div>
  </div>
</footer>
