import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { debounce } from '../../../shared/shared-module/utility/utility.functions';

import { DataService } from '../../../shared/shared-module/services/data.service';
import { LoaderService } from '../../services/loader.service';
import { ToastMessageService } from '../../services/toast.message.service';
import { environment } from '../../../../environments/environment';
import { ApiRequests } from '../../../shared/shared-module/services/apiRequests.service';
import { Constants } from '../../../shared/shared-module/utility/constants';
import { MeetingSharedService } from '../../meeting-shared-module/service/meeting-shared.service';

@Component({
  selector: 'my-meeting-sidebar',
  templateUrl: './my-meeting-sidebar.component.html',
  styleUrls: ['./my-meeting-sidebar.component.scss']
})
export class MyMeetingsSidebarComponent implements OnInit {
  skeletonLoader = false;
  pageLoader = false;
  meetingsData = [];
  pagination = {
    page: 1,
    itemsPerPage: 15
  };
  subscription = new Subject();
  selectedTab: 'upcoming' | 'past' = 'upcoming';
  today = moment(new Date()).format('YYYY-MM-DD');
  addClassCopy = false;
  addEditMeetingModal = false;
  addEditMeeting;
  editRecurrenceType = 'single';
  isloading = true;
  teammateList = [];
  expertList = [];
  externalList = [];

  constructor(
    private service: MeetingSharedService,
    private router: Router,
    private dataService: DataService,
    private loaderService: LoaderService,
    private toastMessageService: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.getMeetingList();
  }

  closeSidebar() {
    this.service.closeMyMeeting.next(true);
  }

  toggleSelectedTab(tab) {
    this.selectedTab = tab;
    this.pagination.page = 1;
    this.meetingsData = [];
    this.getMeetingList();
  }

  getMeetingList(isScroll?) {
    if (!isScroll) {
      this.pagination.page = 1;
      this.meetingsData = [];
      this.skeletonLoader = true;
    }
    this.service
      .getMeetings(
        this.selectedTab,
        this.pagination.page,
        this.pagination.itemsPerPage
      )
      .pipe(distinctUntilChanged(), takeUntil(this.subscription))
      .subscribe(
        (response) => {
          this.skeletonLoader = false;
          this.pageLoader = false;
          this.updateMeetingListView(response);
        },
        () => {
          this.skeletonLoader = false;
          this.meetingsData = [];
        }
      );
  }

  updateMeetingListView(response) {
    if (response.meetings.length > 0) {
      response.meetings.forEach((meeting) => {
        this.getMeetingTime(meeting);
      });
      this.skeletonLoader = false;
      this.pagination.page = response.next_page;
      this.concatMeetingList(response.meetings);
    } else {
      // this.isMeetingRecieved = false;
      this.skeletonLoader = false;
    }
  }

  getMeetingTime(meeting) {
    const utcStartDateObj = moment
      .utc(
        moment(
          meeting.date + ' ' + meeting.start_time,
          'YYYY-MM-DD HH:mm A'
        ).format('YYYY-MM-DD HH:mm:ss')
      )
      .tz(moment.tz.guess());

    const localDate = utcStartDateObj.format('YYYY-MM-DD'); // getting current local date
    meeting.date = localDate;
  }

  concatMeetingList(response) {
    if (this.meetingsData.length === 0) {
      // this.isMeetingRecieved = true;
      this.meetingsData = this.groupByDate(response);
    } else {
      this.meetingsData = this.meetingsData.concat(this.groupByDate(response));
    }
  }

  handleScrollEvent() {
    if (this.pagination.page !== null) {
      this.pageLoader = true;
      this.getMeetingList(true);
    }
  }

  groupByDate(data) {
    let sortedList = data.reduce((groups, each) => {
      const date = each.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(each);
      return groups;
    }, {});

    sortedList = Object.keys(sortedList).map((date) => {
      return {
        date,
        meeting: sortedList[date]
      };
    });
    return sortedList;
  }

  getInitial(initialName) {
    if (initialName) {
      const getNameInitials = initialName.split(' ');
      return getNameInitials[0][0];
    } else {
      return '';
    }
  }

  addClassForCopy() {
    this.addClassCopy = true;
    setTimeout(() => {
      this.addClassCopy = false;
    }, 1000);
  }

  updateMeeting(meetingData) {
    this.toggleSelectedTab(
      meetingData?.meeting_kind == 'offline' ? 'past' : 'upcoming'
    );
  }

  redirect(meeting, openType) {
    if (this.dataService.user?.role !== 'pm_lead' && meeting?.project?.id) {
      this.getProjectDetail(meeting, openType);
    } else {
      this.handleNavigation(meeting, openType);
    }
  }

  getProjectDetail(meeting, openType) {
    this.loaderService.show();
    this.service.getProjectDetailById(meeting?.project?.id, true).subscribe(
      (response) => {
        this.loaderService.hide();
        if (response?.project) {
          let errorMessage = false;
          // in case user is not assigned on project and non-unpaid project
          if (
            response.project.users.findIndex(
              (x) => x.id == this.dataService.user?.id
            ) == -1 &&
            !(
              response.project.status == 'pending' &&
              !response.project.first_payment_date
            )
          ) {
            errorMessage = true;
          }
          this.handleNavigation(meeting, openType, errorMessage);
        }
      },
      () => {
        this.loaderService.hide();
        this.toastMessageService.showToastMessage(
          Constants.toastMessages.error,
          5000,
          true
        );
      }
    );
  }

  handleNavigation(meeting, openType, errorMessage = false) {
    if (errorMessage === true) {
      this.toastMessageService.showToastMessage(
        "You don't have access to this buildcard",
        5000,
        true
      );
      return;
    }
    const baseUrl = environment.BASE_URL;
    this.closeSidebar();
    let url = '';
    if (openType === 'project') {
      if (meeting?.project?.id) {
        url = `projects/${meeting?.project?.id}/basic-information/project-detail`;
      } else if (meeting?.pod?.id) {
        url = `managers/squads/${meeting?.pod?.id}/meetings`;
      }
    } else if (openType === 'meeting') {
      if (meeting?.project?.id) {
        url = `${baseUrl}projects/${meeting?.project?.id}/meetings/${meeting.id}?id=${meeting.id}`;
      } else if (meeting?.pod?.id) {
        url = `${baseUrl}managers/squads/${meeting?.pod?.id}/meetings`;
      }
    }
    if (url) {
      window.open(url, '_blank');
    }
  }

  hidePopup = debounce((data) => {
    if (
      data.participant_count > 0 &&
      data['showParticipants'] &&
      !data['popoverHover']
    ) {
      data['showParticipants'] = false;
    }
  }, 700);

  getParticipantList(meeting) {
    meeting['popoverHover'] = false;
    meeting['showParticipants'] = true;
    this.isloading = false;
    if (meeting.participant_count > 0 && meeting.project?.id && meeting.id) {
      this.isloading = true;
      this.teammateList = [];
      this.expertList = [];
      this.externalList = [];
      this.service
        .getParticipantList(meeting.project?.id, meeting.id)
        .subscribe(
          (data) => {
            this.isloading = false;
            if (data?.teammate?.length > 0) {
              this.teammateList = data.teammate;
            }
            if (data?.expert?.length > 0) {
              this.expertList = data.expert;
            }
            if (data?.external?.length > 0) {
              this.externalList = data.external;
            }
          },
          () => {
            this.isloading = false;
            this.externalList = [];
            this.expertList = [];
            this.teammateList = [];
          }
        );
    } else if (meeting.pod?.id && meeting?.members?.teammate?.length > 0) {
      this.teammateList = meeting.members.teammate;
      this.expertList = [];
      this.externalList = [];
    }
  }

  getSystemMeetingTime(meeting) {
    const sysTz = moment.tz.guess();
    const getSysTz = moment.tz.zone(sysTz).abbr(new Date().getTime());
    const sysTzValut = this.containsNumber(getSysTz)
      ? `(GMT ${this.insertColon(getSysTz)})`
      : getSysTz;
    return `${moment(meeting?.start_date_time).format('hh:mma')} - ${moment(
      meeting?.end_date_time
    ).format('hh:mma')} ${sysTzValut}`;
  }

  containsNumber(str) {
    return /\d/.test(str);
  }

  insertColon(str) {
    if (str.length === 5) {
      const firstPart = str.substring(0, 3);
      const secondPart = str.substring(3);
      return `${firstPart}:${secondPart}`;
    } else {
      return str;
    }
  }
}
